





































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  @Prop({ type: Boolean, default: true }) readonly backButton: boolean;

  get proeminent(): boolean {
    return true;
    // return this.$store.direct.state.mobile.orientation == OrientationMode.PORTRAIT;
  }
}
